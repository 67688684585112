let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Nassr Alkholy",
    "role": "Entwickler",
    "roleDescription": " da ich ständig auf der Suche nach neuen Herausforderungen bin. Daher nutze ich meine Freizeit oft dazu, zu experimentieren und kontinuierlich neue Projekte zu kreieren.",
    "socialLinks":[
        
        {
          "name":"github",
          "url":"http://github.com/nimx202",
          "className":"fa fa-github"
        },
        {
          "name":"Tatu",
          "url":"https://tatu.nassralkholy.de",
          "className":"fa fa-android"
        }
      ],
    "aboutme":"In den Jahren 2023-2024 habe ich die Q1 am Alexander-von-Humboldt-Gymnasium in Bremen besucht, um den schulischen Teil meiner Fachhochschulreife zu absolvieren. Ich plane nun, ein einjähriges Praktikum zu absolvieren, um mein Fachabitur abzuschließen. Danach beabsichtige ich, Anwendungsentwicklung zu studieren.",
    //"address":"Bremen, Deutschland",
    "website":"https://nassralkholy.de",
    "email":"mail@nassralkholy.de",
    "education":[
      {
        "UniversityName":"Alexander-von-Humboldt-Gymnasium",
        "specialization":"Mittelstufe",
        "MonthOfPassing":"sep",
        "YearOfPassing":"2018-2022",
        "Achievements":"Erweiterter Berufsbildungsreife (ErwBBR)"
      },
      {
        "UniversityName":"Alexander-von-Humboldt-Gymnasium",
        "specialization":"Oberstufe",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2022-2024",
        "Achievements":"Mittlere Schulabschluss (MSA)"
      },
      { 
        "UniversityName":"Alexander-von-Humboldt-Gymnasium",
        "specialization":"Q1",
        "MonthOfPassing":"Jul",
        "YearOfPassing":"2023-2024",
        "Achievements":"Fachhochschulreife (FHR)"
      }
    ],
    "work":[
      {
        "CompanyName":"Dmasmoda",
        "specialization":"Bremen, Deutschland",
        "MonthOfLeaving":"feb",
        "YearOfLeaving":"2023",
        "Achievements":"Ich war für die Entwicklung der Website und interner Applikationen der Firma zuständig. Meine Aufgaben waren die Entwicklung neuer Funktionen und das Hinzufügen neuer Produkte auf die Website der Firma."
      },
      {
        "CompanyName":"Altenpflegeheim oldenburg",
        "specialization":"Hatten, Deutschland",
        "MonthOfLeaving":"Jul",
        "YearOfLeaving":"2023- Jan 2024",
       // "Achievements":"Some Achievements"
      }/*,
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      }*/
    ],
    "skillsDescription":"Sprachen kentnisse",
    "skills":[
      {
        "skillname":"java"
      },
      {
        "skillname":"python"
      },
      {
        "skillname":"reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData
